import React from "react";
import ALFlag from "@assets/svg/flags/AL.svg";
import GBFlag from "@assets/svg/flags/GB.svg";
import { useTranslation } from "react-i18next";
import {showError} from "@utils/helpers";

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const switchLanguage = (lang) => {
    i18n
      .changeLanguage(lang)
      .then(() => localStorage.setItem("POSLang", lang))
      .catch(() => showError(t('change_language_error')));
  };

  return (
    <div className="max-h-6 align-middle ml-5">
      <button
        id="albanian"
        className={`px-2 py-1 ${
          i18n.language === "al"
            ? "bg-primary-100 border-primary-200"
            : "border-r-0"
        } border rounded-md rounded-r-none focus:outline-none`}
        onClick={() => switchLanguage("al")}
      >
        <img className="h-4" src={ALFlag} alt="AL" />
      </button>
      <button
        id="english"
        className={`px-2 py-1 ${
          i18n.language === "en"
            ? "bg-primary-100 border-primary-200"
            : "border-l-0"
        } border rounded-md rounded-l-none focus:outline-none`}
        onClick={() => switchLanguage("en")}
      >
        <img className="h-4" src={GBFlag} alt="GB" />
      </button>
    </div>
  );
};

export default LanguageSelector;
