import API from "@utils/plugins/API";

const PosService = {
  events: (id, params) => {
    return API.get(`/point-of-sales/${id}/events`, {params})
  },
  print: (id, eventId) => {
    return API.post(`/point-of-sales/${id}/events/${eventId}/fire`)
  },
  refund: (id) => {
    return API.post(`/transactions/${id}/refund`, {})
  },
  update: (id, amount, currencyCode) => {
    return API.put(`/point-of-sales/${id}/permanent-transaction-code`, {
      amount,
      currencyCode: currencyCode
    })
  },
  reset: (id) => {
    return API.post(`/point-of-sales/${id}/permanent-transaction-code/reset`)
  },
  downloadTransactions: (id, params) => {
    return API.get(`/point-of-sales/${id}/export-transactions-xlsx`, {params})
  },
}

export default PosService
