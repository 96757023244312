import React from 'react';
import Landing from "@assets/images/landing.png";
import Background from "@assets/images/O-landing.png";
import Logo from "@core/partials/Logo";
import LanguageSelector from "@components/Language/LanguageSelector";

const AuthLayout = ({children}) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 flex-1 m-auto">
      <div className="flex flex-1 justify-center items-center m-auto min-h-screen bg-white md:justify-start ">
        <div className="max-w-md w-full space-y-8 m-auto px-4">
          <div className="flex justify-between">
            <Logo />
            <LanguageSelector />
          </div>
          {children}
        </div>
      </div>
      <div
        className={
          "flex flex-1 h-full m-auto justify-center inline-flex items-center min-h-screen h-screen hidden lg:flex"
        }
        style={{
          backgroundImage: `url(${Background}), linear-gradient(135.45deg, #7570FF 2.25%, #5B56E8 61.41%)`,
          backgroundSize: "contain",
          marginInline:'auto',


        }}
      >
        <img src={Landing} style={{ height: "620px" }} alt={'landing'} />
      </div>
    </div>
  );
};

export default AuthLayout;
