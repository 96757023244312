import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import alTranslations from "./al";
import enTranslations from "./en";

if (localStorage.getItem('POSLang') !== 'en' && localStorage.getItem('POSLang') !== 'al') {
  localStorage.setItem('POSLang', 'en')
}

const language = localStorage.getItem('POSLang')

const resources = {
  en: {
    translation: enTranslations
  },
  al: {
    translation: alTranslations
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
