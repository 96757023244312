import {combineReducers} from 'redux';
import authenticationReducer from './authentication/Reducer';
import spinnerReducer from './spinner/Reducer';

const Reducers = combineReducers({
    authenticationReducer,
    spinnerReducer,
});

export default Reducers;
