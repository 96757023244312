import React, {useState} from "react";
import Layout from "@hoc/layouts/Layout";
import TransactionsTable from "@components/Transactions/TransactionsTable";
import TabRadioButtons from "@core/radio/TabRadioButtons";
import NewPayment from "@components/Payment/NewPayment";
import {useTranslation} from "react-i18next";

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState('newPayment');
  const { t } = useTranslation();
  const options = [
    { label: t('new_payment'), value: 'newPayment' },
    { label: t('transactions'), value: 'transactions' }
  ];

  return (
    <Layout>
      <div className="border bg-white rounded justify-between items-center mb-5 py-8">
        <TabRadioButtons
          options={options}
          selectedOptionState={[activeTab, setActiveTab]}
          className="w-72 mx-auto"
        />
        <div className="p-0 sm:p-5">
          {activeTab === "newPayment" ? (
            <NewPayment />
          )  : (
            <TransactionsTable/>
          ) }
        </div>
      </div>
    </Layout>
  );
}

export default DashboardPage;
