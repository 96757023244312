import React from "react";

const TabRadioButtons = ({ selectedOptionState, options = [], ...props }) => {
  const [selectedOption, setSelectedOption] = selectedOptionState;

  return (
    <div {...props}>
      <div className="flex items-center text-gray-700 h-9 px-1 text-sm border rounded-lg h-9">
        {options.map(({label, value}) => (
          <h1
            key={value}
            className={`w-10 text-center flex-1 rounded-md cursor-pointer p-1
            ${selectedOption === value ? "bg-primary text-white" : ""}`}
            onClick={() => setSelectedOption(value)}
          >
            {label}
          </h1>
        ))}
      </div>
    </div>
  );
};

export default TabRadioButtons;
