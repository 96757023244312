const al = {
  "login": "Identifikohu",
  "logout": "Dil",
  "logout_from_account": "Dil nga llogaria",
  "logout_confirm_message": "Jeni i sigurt që doni të dilni nga llogaria juaj?",
  "back": "Kthehu",
  "new_payment": "Faturë e re",
  "transactions": "Transaksionet",
  "reset": "Rivendos",
  "currency": "Monedha",
  "continue": "Vazhdo",
  "report": "Raport",
  "this_year": "Ky vit",
  "this_month": "Ky muaj",
  "this_week": "Kjo javë",
  "download": "Shkarko",
  "reference_number": "Numri i referencës",
  "terminal": "Terminali",
  "date_time": "Data/Ora",
  "type": "Tipi",
  "client": "Klienti",
  "amount": "Vlera",
  "status": "Statusi",
  "actions": "Veprime",
  "search": "Kërko...",
  "tip": "Bakshish",
  "payment": "Pagesë",
  "confirmed": 'Konfirmuar',
  "refunded": 'Rimbursuar',
  "partially_refunded": 'Rimbursuar pjesërisht',
  "canceled": 'Anuluar',
  "pending": 'Në pritje',
  "print": "Printo",
  "please_wait": "Ju lutem prisni...",
  "refund": "Rimburso",
  "refund_payment": "Rimburso pagesën",
  "refund_confirm_message": "Jeni i sigurt për rimbursimin e pagesës me vlerë ",
  "change_language_error": "Gjuha e zgjedhur nuk u ruajt"
}

export default al
