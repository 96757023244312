import React from 'react';
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import DashboardPage from "@pages/DashboardPage";
import LoginPage from "@pages/LoginPage";
import AuthRoute from "@router/AuthRoute";
import GuestRoute from "@router/GuestRoute";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={LoginPage} />
        <AuthRoute exact path="/" component={DashboardPage} />

        <Redirect from='*' to='/'/>
      </Switch>
    </Router>
  );
};

export default Routes;
