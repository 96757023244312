import React, {useEffect, useRef, useState} from "react";
import PrimaryButton from "@core/buttons/electrons/PrimaryButton";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import PosService from "@services/PosService";
import {showError, showSuccess} from "@utils/helpers";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import Select from "@core/dropdowns/Select";
import Axios from "axios";
import {API_URL} from "@constants/index";
import {authenticate} from "@redux/authentication/Action";
import API from "@utils/plugins/API";
import {useTranslation} from "react-i18next";

const currencies = {
  ALL: {value: 'ALL', label: 'Lekë'},
  EUR: {value: 'EUR', label: 'Euro'},
  USD: {value: 'USD', label: 'USD'},
  GBP: {value: 'GBP', label: 'GBP'},
}

const NewPayment = () => {

  const dispatch = useDispatch()
  const posId = useSelector((state) =>
    _.get(state, "authenticationReducer.posId", null)
  );
  const token = useSelector((state) =>
    _.get(state, "authenticationReducer.accessToken", null)
  );
  const currencyCode = useSelector((state) =>
    _.get(state, "authenticationReducer.currencyCode", 'ALL')
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(showSpinner('Please wait...'))
    API.get(`/point-of-sales/${posId}`).then((response) => {
      const currencyCode = _.get(response, 'data.data.pointOfSale.currencyCode', 'ALL')
      dispatch(authenticate({posId, currencyCode, accessToken: token, credentials: { posId, token }}));
    })
      .catch((err) => {
        showError(err.response.data.message);
      }).finally(() => {
      dispatch(hideSpinner())
    });
  }, []);

  const inputRef = useRef()
  const [value, setValue] = useState('')
  const [currency, setCurrency] = useState(currencies[currencyCode])

  const resetValue = () => {
    reset();
  }

  const update = (e) => {
    e.preventDefault()
    dispatch(showSpinner('Please wait...'))
    PosService.update(posId, value, currencyCode).then((response) => {
      showSuccess(response.data.message);
      setValue('')
      inputRef.current.focus();
    })
      .catch((err) => {
        showError(err.response.data.message);
      }).finally(() => {
      dispatch(hideSpinner())
    });
  }

  const reset = () => {
    setValue('')
    inputRef.current.focus();
    dispatch(showSpinner('Please wait...'))
    PosService.reset(posId).catch((err) => {
      showError(err.response.data.message);
    }).finally(() => {
      dispatch(hideSpinner())
    });
  }

  function checkAmount(e) {
    if (e.key !== "Backspace") {
      if (value.includes('.')) {
        if (value.split('.')[1].length >= 2) {
          let num = parseFloat(value);
          let correctNum = num.toFixed(2);
          setValue(correctNum);
          e.preventDefault();
        }
      }
    }
  }

  return (
    <form className={'flex flex-col justify-center items-center p-20'} onSubmit={update}>
      <input
        ref={inputRef}
        autoFocus
        type="number"
        step={"0.01"}
        min='1'
        value={value}
        onKeyDown={(e) => {
          checkAmount(e)
        }}
        onChange={(e) => setValue(e.target.value)}
        className={'text-6xl focus:outline-none border-b-2 font-bold h-24 xs:w-60 md:w-91 text-center appearance-none'}
        placeholder="0.00"
      />
      <span onClick={resetValue} className="mt-8 text-primary font-medium cursor-pointer">{t('reset')}</span>
      <Select
        disabled
        className={'mt-5'}
        placeholder={t('currency')}
        options={[{value: 'ALL', label: 'Lekë'}, {value: 'EUR', label: 'Euro'}]}
        selectedOptionState={[currency, setCurrency]}
      />
      <div className={'mt-7'}>
        <PrimaryButton label={t('continue')}/>
      </div>
    </form>
  );
}

export default NewPayment;
