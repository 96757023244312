import React, {useEffect, useState} from 'react';
import {showError} from '@utils/helpers';
import {useDispatch, useSelector} from "react-redux";
import {authenticate} from "@redux/authentication/Action";
import Axios from "axios";
import {API_URL} from "@constants/index";
import {useHistory} from "react-router-dom";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import _ from 'lodash';
import {useTranslation} from "react-i18next";

const windowWidth = window.innerWidth;

const LoginForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [posId, setPosId] = useState('')
  const [token, setToken] = useState('')
  const { t } = useTranslation();

  const credentials = useSelector((state) =>
    _.get(state, "authenticationReducer.credentials", null)
  );

  useEffect(() => {
    if (credentials) {
      setPosId(credentials.posId)
      setToken(credentials.token)
    }
  }, [])

  const onSubmit = (e) => {
    e.preventDefault()
    const APIClient = Axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + token,
      },
    });
    dispatch(showSpinner(t('please_wait')))
    APIClient.get(`/point-of-sales/${posId}`).then((response) => {
      const currencyCode = _.get(response, 'data.data.pointOfSale.currencyCode', 'ALL')
      dispatch(authenticate({posId, currencyCode, accessToken: token, credentials: { posId, token }}));
      history.push('/');
    })
      .catch((err) => {
        showError(err.response.data.message);
      }).finally(() => {
      dispatch(hideSpinner())
    });
  }

  return (
    <>
      <div>
        <h2 className="text-3xl font-extrabold text-gray-900 leading-9">
          {t('login')}
        </h2>
      </div>
      <form className="mt-12 space-y-6" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700 leading-5">
              POS ID
            </label>
            <input
              value={posId}
              className="mb-5 appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Pos ID"
              onChange={(e) => setPosId(e.target.value)}
            />
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700">
              Token
            </label>
            <textarea
              value={token}
              rows={windowWidth < 640 ? 8 : 5 }
              className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Token"
              onChange={(e) => setToken(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('login')}
          </button>
        </div>
      </form>
    </>
  )
}

export default LoginForm;

