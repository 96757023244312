import React, {useState} from "react";
import {useDispatch} from "react-redux";
import ConfirmModal from "@core/modal/ConfirmModal";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import PosService from "@services/PosService";
import {showError, showSuccess} from "@utils/helpers";
import {amountFormatter} from '@utils/helpers';
import {useTranslation} from "react-i18next";

const RefundButton = ({bookkeeping, onSuccess, isDisabled}) => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const refund = () => {
    dispatch(showSpinner(t('please_wait')))
    PosService.refund(bookkeeping.transactionId).then((response) => {
      showSuccess(response.data.message)
      onSuccess();
    })
      .catch((err) => {
        showError(err.response.data.message);
      }).finally(() => {
      dispatch(hideSpinner())
    });
  }

  const onClick = () => !isDisabled && setOpenModal(true)

  return (
    <div>
      <button onClick={onClick}
              className={`${isDisabled ? 'text-gray-500 cursor-default' : 'text-red-700'} font-medium cursor-pointer mx-2`}>{t('refund')}
      </button>
      {openModal && (
        <ConfirmModal
          setOpenModal={setOpenModal}
          title={t('refund_payment')}
          confirmModalLabel={t('refund')}
          onConfirm={refund}
          size="xl"
        >
          <div>
            {t('refund_confirm_message')}{amountFormatter(bookkeeping.amount, bookkeeping.currencyCode)}?
          </div>
        </ConfirmModal>
      )}
    </div>
  );
}

export default RefundButton;
