import React from "react";
import DefaultBadge from "@core/badges/DefaultBadge";

const GrayBadge = ({ text }) => {
  return (
    <DefaultBadge text={text} bgColor="bg-gray-200" textColor="text-gray-800" />
  );
};

export default GrayBadge;
