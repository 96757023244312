import GreenBadge from "@core/badges/GreenBadge";
import RedBadge from "@core/badges/RedBadge";
import IndigoBadge from "@core/badges/IndigoBadge";
import GrayBadge from "@core/badges/GrayBadge";

export const statusFormatter = (status, t) => {
  switch (status) {
    case "confirmed":
      return <GreenBadge text={t("confirmed")} />;
    case "refunded":
      return <IndigoBadge text={t("refunded")} />;
    case "partially-refunded":
      return <GrayBadge text={t("partially_refunded")} />;
    default:
      return <RedBadge text={t("canceled")} />;
  }
};

export const statusOptions = [
  { value: 'pending', label: 'pending' },
  { value: 'confirmed', label: 'confirmed' },
  { value: 'canceled', label: 'canceled' },
  { value: 'refunded', label: 'refunded' },
  { value: 'partially-refunded', label: 'partially_refunded' }
];

export const typeOptions = [
  { value: 'payment', label: 'payment' },
  { value: 'tip', label: 'tip' }
];
