import React from "react";
import LogoSvg from "@assets/svg/POK.svg";

const Logo = () => {
  return (
    <div className="flex items-center">
      <img
        src={LogoSvg}
        alt="POK Logo"
        className="mt-0 pt-0 pr-3 border-r-2 w-18"
      />
      <label className="pl-3 block text-base font-normal text-gray-900 leading-6 mr-5">
        POS
      </label>
    </div>
  );
};

export default Logo;
