import React from "react";
import DefaultButton from "@core/buttons/electrons/DefaultButton";
import {FaQuestion} from "react-icons/fa";
import RedButton from "@core/buttons/electrons/RedButton";
import {useTranslation} from "react-i18next";

const ConfirmModal = ({
                        setOpenModal,
                        title,
                        children,
                        confirmModalLabel,
                        onConfirm,
                        disableSubmitButton = false,
                        size = 'lg'
                      }) => {
    const { t } = useTranslation();

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto sm:object-scale-down sm: bottom-5 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 sm:justify-center sm:content-center  ">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen "
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className={`inline-block align-bottom  bg-white rounded-lg text-left overflow-hidden m-auto justify-items-center shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-${size} `}>
          <div className="bg-white px-4 pt-5 pb-4  sm:p-6 sm:pb-4  ">
            <div className="sm:flex sm:items-start ">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 ">
                <FaQuestion className={'text-red-600'} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {title}
                </h3>
                <div className="mt-2">
                  <div className="flex text-sm text-gray-500">{children}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-items-center bg-gray-50 px-4 py-3 md:flex-row  md:flex-row  md:flex-row-reverse">
            <RedButton
              disabled={disableSubmitButton}
              label={confirmModalLabel}
              onClick={() => {
                onConfirm();
                setOpenModal(false);
              }}
              className={'mx-5 mt-2'}
            />
            <DefaultButton label={t('back')} onClick={() => setOpenModal(false)} className={'mx-5 mt-2'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
