import React, {useEffect, useMemo, useState} from 'react';
import Datatable from "@core/table/CDatatable";
import PrintButton from "@components/Transactions/partials/PrintButton";
import PosService from "@services/PosService";
import {useSelector} from "react-redux";
import _ from "lodash";
import moment from "moment"
import RefundButton from "@components/Transactions/partials/RefundButton";
import DownloadTransactions from "@components/Transactions/DownloadTransactions";
import {useTranslation} from "react-i18next";
import {statusFormatter} from "@utils/functions/Transactions";
import {getPastDateRange} from "@utils/helpers";

const TransactionsTable = () => {
  const [items, setItems] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [intervalKey, setIntervalKey] = useState(0);
  const [dateRange, setDateRange] = useState(getPastDateRange('year'));
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const posId = useSelector((state) =>
    _.get(state, "authenticationReducer.posId", null)
  );
  const { t } = useTranslation();

  const fetchData = async (params) => {
    params.dateFrom = dateRange[0];
    params.dateTo = dateRange[1];
    if (!!selectedStatus) {
      params.status = selectedStatus.value;
    }
    if (!!selectedType) {
      params.type = selectedType.value;
    }
    const res = await PosService.events(posId, params)
    setItems(res.data.data.posEvents)
    setTotalRows(res.data.data.countData.recordCount)
  }

  const headers = useMemo(
    () => [
      {
        id: "referenceNr",
        name: t('reference_number'),
        cell: (row) => {
          const referenceNr = _.get(row, 'data.bookkeeping.transaction.referenceNr', '-')
          return (
            <div>{referenceNr}</div>
          )
        }
      },
      {
        id: "pointOfSaleName",
        name: t('terminal'),
        cell: (row) => {
          const terminal = _.get(row, 'data.pointOfSale.name', '')
          return <div>{terminal}</div>;
        },
      },
      {
        id: "datetime",
        name: t('date_time'),
        cell: (row) => {
          const createdAt = _.get(row, 'data.bookkeeping.createdAt', '')
          return moment.utc(createdAt).local().format('DD/MM/YYYY HH:mm:ss');
        },
      },
      {
        id: "type",
        name: t('type'),
        cell: (row) => {
          const type = _.get(row, 'data.bookkeeping.type', '')
          return t(type);
        },
      },
      {
        id: "client",
        name: t('client'),
        cell: (row) => {
          const fullName = _.get(row, 'data.bookkeeping.otherPartyLabel', '-')
          return (
            <div>{fullName}</div>
          )
        },
      },
      {
        id: "amount",
        name: t('amount'),
        cell: (row) => {
          const amount = _.get(row, 'data.bookkeeping.amount', 0)
          const currencyCode = _.get(row, 'data.bookkeeping.currencyCode', 'ALL')
          return (
            <div>{amount} {currencyCode}</div>
          )
        },
      },
      {
        id: "status",
        name: t('status'),
        cell: (row) => {
          const status = _.get(row, 'data.bookkeeping.transaction.status', 0)
          return statusFormatter(status, t);
        },
        minWidth: "115px"
      },
      {
        id: "actions",
        name: t('actions'),
        selector: ({id, data}) => {
          const bookkeeping = _.get(data, 'bookkeeping', '')
          return (
            <div className={"flex align-center justify-start"}>
              <PrintButton id={posId} eventId={id} />
              {bookkeeping.type !== 'tip' &&
                <RefundButton
                  bookkeeping={bookkeeping}
                  onSuccess={fetchData}
                  isDisabled={
                    bookkeeping.transaction.isRefunded ||
                    moment().isAfter(moment(bookkeeping.date).add(1, "days"))
                  }
                />
              }
            </div>
          );
        },
        width: '170px'
      },
    ],
    [posId, t]
  );

  useEffect(() => {
    setInterval(() => {
      setIntervalKey(prevState => prevState + 1)
    }, 10000)
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <DownloadTransactions dateRange={dateRange}
                            onDateRangeChange={setDateRange}
                            selectedStatus={selectedStatus}
                            onSelectedStatusChange={setSelectedStatus}
                            selectedType={selectedType}
                            onSelectedTypeChange={setSelectedType} />
      <Datatable
        data={items}
        headers={headers}
        totalRows={totalRows}
        initialPageSize={10}
        updateKey={intervalKey}
        getData={fetchData}
        isLoading={false}
        extraDependencies={[dateRange, selectedStatus, selectedType]}
      />
    </div>
  )
}

export default TransactionsTable;

