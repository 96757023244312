import React, {useState} from 'react';
import {logout, saveCredentials} from "@redux/authentication/Action";
import {useDispatch} from "react-redux";
import RedButton from "@core/buttons/electrons/RedButton";
import Logo from "@core/partials/Logo";
import ConfirmModal from "@core/modal/ConfirmModal";
import LanguageSelector from "@components/Language/LanguageSelector";
import {useTranslation} from "react-i18next";

const Header = () => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);
  const [logoutCLicks, setLogoutCLicks] = useState(0);
  const { t } = useTranslation();

  const handleLogout = () => {
     dispatch(saveCredentials());
     dispatch(logout());
  }

  const clickingLogout = () => {
    setLogoutCLicks(logoutCLicks + 1);
    if(logoutCLicks === 10) {
      setOpenModal(true);
      setLogoutCLicks(0);
    }
  }

  return (
    <nav className="bg-white">
      <div className="relative flex items-center justify-between px-6 h-16 shadow">
        <div className="flex">
          <Logo />
          <LanguageSelector />
        </div>
        <div>
          <RedButton label={t('logout')} onClick={() => clickingLogout() } />
          {openModal && (
            <ConfirmModal
              setOpenModal={setOpenModal}
              title={t('logout_from_account')}
              confirmModalLabel={t('logout')}
              onConfirm={handleLogout}
            >
              <>{t('logout_confirm_message')}</>
            </ConfirmModal>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Header;

