const en = {
  "login": "Login",
  "logout": "Logout",
  "logout_from_account": "Logout from account",
  "logout_confirm_message": "Are you sure that you want to logout from your account?",
  "back": "Back",
  "new_payment": "New payment",
  "transactions": "Transactions",
  "reset": "Reset",
  "currency": "Currency",
  "continue": "Continue",
  "report": "Report",
  "this_year": "This year",
  "this_month": "This month",
  "this_week": "This week",
  "download": "Download",
  "reference_number": "Reference Number",
  "terminal": "Terminal",
  "date_time": "Date/Time",
  "type": "Type",
  "client": "Client",
  "amount": "Amount",
  "status": "Status",
  "actions": "Actions",
  "search": "Search...",
  "tip": "Tip",
  "payment": "Payment",
  "confirmed": 'Confirmed',
  "refunded": 'Refunded',
  "partially_refunded": 'Partially refunded',
  "canceled": 'Canceled',
  "pending": 'Pending',
  "print": "Print",
  "please_wait": "Please wait...",
  "refund": "Refund",
  "refund_payment": "Refund payment",
  "refund_confirm_message": "Are you sure that you want to refund the payment with amount ",
  "change_language_error": "Selected language was not saved"
}

export default en
