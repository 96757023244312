import React from 'react';
import {useDispatch} from "react-redux";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import PosService from "@services/PosService";
import {showError, showSuccess} from "@utils/helpers";
import {FaPrint} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const PrintButton = ({id, eventId}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const print = (id) => {
    dispatch(showSpinner(t('please_wait')));
    PosService.print(id, eventId).then((response) => {
      showSuccess(response.data.message)
    })
      .catch((err) => {
        showError(err.response.data.message);
      }).finally(() => {
      dispatch(hideSpinner())
    });
  }

  return (
    <span onClick={() => print(id)} className="text-primary font-bold cursor-pointer mx-2 flex"><FaPrint className={'mr-1'} />{t('print')}</span>
  )
}

export default PrintButton;

