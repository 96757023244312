import {ADD_AUTH, REMOVE_AUTH, SAVE_CREDENTIALS} from '@constants/redux';

export const logout = () => async (dispatch, getState) => {
  dispatch(removeAuth());
};

export const authenticate = (payload) => {
  return {
    type: ADD_AUTH,
    payload,
  };
};

export const removeAuth = () => {
  return {
    type: REMOVE_AUTH,
  };
};

export const saveCredentials = () => {
  return {
    type: SAVE_CREDENTIALS,
  };
};
