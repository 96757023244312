import React from 'react';
import DefaultButton from "@core/buttons/electrons/DefaultButton";

const RedButton = ({disabled = false, ...props}) => {
  return (
    <DefaultButton
      textColor={'text-white'}
      disabled={disabled}
      bgColor={ disabled ? 'bg-gray-300' : 'bg-red-500'}
      bgColorHover={disabled ? 'hover:bg-gray-300' : 'hover:bg-red-600'}
      {...props}
    />
  )
}

export default RedButton;

