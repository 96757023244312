import {notify} from 'react-notify-toast';
import moment from 'moment';

export const showSuccess = (message = 'Success', timeout = 4000) => {
  notify.show(message, 'custom', timeout, {
    background: '#10b981',
    text: 'white',
  });
};
export const showCopiedFlash = (message = 'Success', timeout = 800) => {
  notify.show(message, 'custom', timeout, {
    background: '#EC7F00',
    text: 'white',
  });
};

export const showError = (error, timeout = 4000) => {
  notify.show(error, 'custom', timeout, {
    background: '#ef4444',
    text: 'white',
  });
};

export const showInfo = (message, timeout = 2000) => {
  notify.show(message, 'custom', timeout, {
    background: '#3b82f6',
    text: 'white',
  });
};

export const amountFormatter = (amount, currencyCode = 'EUR', locales = 'en-US') => {
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formattedAmount = parseFloat(amount).toLocaleString(locales, options);
  return `${formattedAmount} ${currencyCode}`;
};

export const getPastDateRange = (key) => {
  switch (key) {
    case 'week':
      return [moment().startOf('week').toDate(), new Date()];
    case 'month':
      return [moment().startOf('month').toDate(), new Date()];
    case 'year':
      return [moment().startOf('year').toDate(), new Date()];
    default:
      return [moment().startOf('day').toDate(), new Date()];
  }
};
