import {ADD_AUTH, REMOVE_AUTH, SAVE_CREDENTIALS} from '@constants/redux';

const authenticationReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_AUTH:
            return {
                ...state,
                ...action.payload,
            };
        case SAVE_CREDENTIALS:
            return {
                ...state,
                credentials: {
                    posId: state.posId,
                    token: state.accessToken,
                }
            };
        case REMOVE_AUTH:
            return {
                credentials: {
                    ...state.credentials
                }
            };
        default:
            return state;
    }
};

export default authenticationReducer
