import React from 'react';
import {Link} from "react-router-dom";

const DefaultButton = (
  {
    className = '',
    label,
    link,
    to,
    onClick,
    bgColor,
    bgColorHover,
    textColor,
    xs = false,
    sm = false,
    ...props
  }) => {
  let sizingClasses = 'py-2 px-4 text-sm'
  if (xs) {
    sizingClasses = 'py-1 px-3 text-xs'
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs'
  }

  const createClassName = `group relative w-full flex justify-center border border-transparent font-medium rounded-md ${textColor ? textColor : 'text-black'} ${sizingClasses} ${bgColor ? bgColor : 'bg-white border border-gray-200'}  ${bgColorHover ? bgColorHover : 'hover:bg-gray-100'} focus:outline-none ${className}`

  if (link) {
    return  (
      <Link
        to={to}
        className={createClassName}
        {...props}
      >
        {label}
      </Link>
    )
  }

  return (
    <button
      onClick={onClick}
      className={createClassName}
      {...props}
    >
      {label}
    </button>
  )
}

export default DefaultButton;

