import React from 'react';
import DefaultButton from "@core/buttons/electrons/DefaultButton";

const PrimaryButton = (props) => {
  return (
    <DefaultButton
      textColor={'text-white'}
      bgColor={'bg-primary'}
      bgColorHover={'hover:bg-primary-600'}
      {...props}
    />
  )
}

export default PrimaryButton;

