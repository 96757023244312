import React, {useEffect, useState} from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import {getPastDateRange, showError} from '@utils/helpers';
import {BsCalendarMinus} from 'react-icons/bs';
import '@assets/css/datepicker/custom-datepicker.css';
import '@assets/css/datepicker/custom-calendar.css';
import PrimaryButton from '@core/buttons/electrons/PrimaryButton';
import TabRadioButtons from '@core/radio/TabRadioButtons';
import {useSelector} from "react-redux";
import _ from "lodash";
import PosService from "@services/PosService";
import {useTranslation} from "react-i18next";
import Select from "@core/dropdowns/Select";
import {statusOptions, typeOptions} from "@utils/functions/Transactions";
import {FaDownload} from "react-icons/fa";

const DownloadTransactions = ({ dateRange,
                                onDateRangeChange,
                                selectedStatus,
                                onSelectedStatusChange,
                                selectedType,
                                onSelectedTypeChange}) => {
  const [dateShortcut, setDateShortcut] = useState('year');

  const posId = useSelector((state) =>
    _.get(state, "authenticationReducer.posId", null)
  );
  const { t } = useTranslation();

  const filters = [
    {label: t('this_year'), value: 'year'},
    {label: t('this_month'), value: 'month'},
    {label: t('this_week'), value: 'week'}
  ];

  const handleDateRangeChange = (date) => {
    setDateShortcut('');
    onDateRangeChange(date);
  };

  const downloadTransactions = () => {
    const params = {
      dateFrom: dateRange[0],
      dateTo: dateRange[1]
    }
    if (!!selectedStatus) {
      params.status = selectedStatus.value;
    }
    if (!!selectedType) {
      params.type = selectedType.value;
    }
    PosService.downloadTransactions(posId, params).then(response => window.open(response.data, '_blank'))
      .catch((err) => showError(err.response.data.message));
  };

  const formatOptionLabel = ({ label }) => t(label);

  useEffect(() => {
    if (dateShortcut) {
      onDateRangeChange(getPastDateRange(dateShortcut));
    }
  }, [dateShortcut]);

  return (
      <div className="flex flex-row items-center justify-between border-gray-100 my-3 py-7">
        <TabRadioButtons
          options={filters}
          selectedOptionState={[dateShortcut, setDateShortcut]}
          className="w-72"
        />
        <div className="flex">
          <DateRangePicker
            format="dd/MM/yyyy"
            clearIcon={null}
            calendarIcon={<BsCalendarMinus className="text-gray-400"/>}
            onChange={handleDateRangeChange}
            value={dateRange}
            className="border rounded-lg w-54 text-sm h-9"/>
          <Select
            placeholder={t('status')}
            options={statusOptions}
            selectedOptionState={[selectedStatus, onSelectedStatusChange]}
            formatOptionLabel={formatOptionLabel}
            isClearable
            className="w-full md:w-48 md:mx-3"
          />
          <Select
            placeholder={t('type')}
            options={typeOptions}
            selectedOptionState={[selectedType, onSelectedTypeChange]}
            formatOptionLabel={formatOptionLabel}
            isClearable
            className="w-full md:w-48 md:mr-3"
          />
          <PrimaryButton
            className="w-auto flex items-center"
            label={
              <>
                <FaDownload />
                <div className="pl-2">{t('download')}</div>
              </>
            }
            onClick={downloadTransactions}
          />
      </div>
    </div>
  )
}

export default DownloadTransactions;
