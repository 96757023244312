import React from 'react';
import Header from "@hoc/partials/Header";

const Layout = ({children}) => {
  return (
    <div>
      <div className={"min-h-screen bg-gray-100"}>
        <div className={"flex-1"}>
          <Header/>
          <div className="xs:mx-0 md:mx-20 pt-10 pb-20">{children}</div>
        </div>
      </div>
    </div>

  );
};

export default Layout;
