import React from "react";
import DefaultBadge from "@core/badges/DefaultBadge";

const IndigoBadge = ({ text }) => {
  return (
    <DefaultBadge text={text} bgColor="bg-primary-100" textColor="text-primary-800" />
  );
};

export default IndigoBadge;
